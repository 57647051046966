import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as TextSVG } from "images/logo-text.svg";

export default React.memo(
  (props: React.SVGAttributes<SVGSVGElement> & { hideText?: boolean }) => (
    <Wrapper>{!props.hideText && <LogoText {...props} />}</Wrapper>
  )
);

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  color: ${(p) => p.theme.colors.neutral2};
  background-color: ${(p) => p.theme.colors.neutral1};
  ${(p) =>
    css`
      ${p.theme.flexStyles.center}
    `}
`;

const LogoText = styled(TextSVG)`
  width: 25%;
  height: auto;
`;
